import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import Embed from '../../../PopUpShop/components/EmbedCode';
import { IntegrationPageHubspotButton } from '../IntegrationsPageHubspotButton/index';
import Form from '../../../PopUpShop/components/Form';
import WidgetPreview from '../../../PopUpShop/components/WidgetPreview';
import { getStoreUrl } from 'lib/store';

export const IntegrationsConfig = (user) => {
  const stores = user?.stores || [];
  const store = stores.find((aStore) => !isEmpty(aStore.slug));
  const storeUrl = getStoreUrl(store?.slug);
  const s3AssetsBaseUrl = process.env.REACT_APP_S3_ASSETS_BASE_URL;

  return {
    beacons: {
      title: 'Beacons',
      subtitle: 'Display your Spring store on your Beacons page.',
      bannerImgArray: [
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Beacons_01.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Beacons_02.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Beacons_03.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Beacons_04.png'
      ],
      overviewContent:
        'Showcase and sell products directly on your Beacons page to boost sales and broaden your community. <br/><br/> Beacons is an all in one platform for creators that enables you to sell through your link in bio',

      features: [
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Showcase.svg',
          title: 'Store widget',
          text: 'Display products inline with your links'
        },
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/views.svg',
          title: 'Store views',
          text: 'Drive traffic to your store'
        }
      ],
      resources: [
        {
          title: 'Beacons Help Center',
          link: 'https://help.beacons.ai/en',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        },
        {
          title: 'Support',
          link: 'https://spring4creators.zendesk.com/hc/en-us/requests/new',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        }
      ],
      setup: {
        setupIcon:
          'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/lightning_icon.png',
        setupTime: 'Setup time 3 minutes',
        prelude: (
          <div className='integrations_page_setup_prelude'>
            The following steps will require you to leave Spring and adjust some
            preferences in your Beacons account.
          </div>
        ),
        shouldShowCTA: false,
        steps: [
          {
            step: 1,
            text: 'Visit&nbsp;<a href="https://beacons.ai/" class="integrations_step_link" target="_blank">Beacons.ai</a>&nbsp;and login or create an account.'
          },
          {
            step: 2,
            text: 'Click on&nbsp;<span class="integrations_steps_bold">Link in Bio</span>&nbsp;from the left-hand menu.'
          },
          {
            step: 3,
            text: 'Click on the&nbsp;<span class="integrations_steps_bold">+ Add block</span>&nbsp;button (big black button near the top)'
          },
          {
            step: 4,
            text: 'Click on&nbsp;<span class="integrations_steps_bold">Integrations</span>, then find and click on&nbsp;<span class="integrations_steps_bold">Spring</span>.'
          },
          {
            step: 5,
            text: 'Enter your&nbsp;<span class="integrations_steps_bold">Spring Store URL</span>&nbsp;and click&nbsp;<span class="integrations_steps_bold">Save</span>.'
          }
        ],
        advice: `<span class="advice_bold">Your Spring Store URL:</span>&nbsp;<a href=${storeUrl} class="integrations_step_link" target="_blank">${storeUrl}</a>`
      }
    },
    youtube: {
      title: 'YouTube',
      subtitle: 'Showcase and sell products directly on your YouTube channel.',
      bannerImgArray: [
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/YouTube_new_01.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/YouTube_new_02.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/YouTube_new_03.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/YouTube_new_04.png'
      ],
      overviewContent:
        'Put product tags in your YouTube videos. Show Spring products under your video or in the video description if you have ads. This way, viewers can discover and choose your products without leaving YouTube.',
      features: [
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Store.svg',
          title: 'Store button',
          text: 'Tag products to your video'
        },
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Shelf.svg',
          title: 'Product shelf',
          text: 'Display products below your videos'
        },
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Mange.svg',
          title: 'Channel store',
          text: 'Customize your product selection'
        },
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Live.svg',
          title: 'Live shopping',
          text: 'Tag products to your live stream'
        }
      ],
      resources: [
        {
          title: 'How to tag products',
          link: 'https://support.google.com/youtube/answer/10191533?hl=en',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        },
        {
          title: 'YouTube guides',
          link: 'https://support.google.com/youtube/answer/12257682?hl=en',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        },
        {
          title: 'Creator resources',
          link: 'https://www.spri.ng/creator-resources/youtube-integration-faqs',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        },
        {
          title: 'Support',
          link: 'https://spring4creators.zendesk.com/hc/en-us/requests/new',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        }
      ],
      setup: {
        eligibility:
          'To set up a store on YouTube, your channel should have a minimum of 1k subscribers and be approved for monetization. <a class="integrations_step_link" href="https://support.google.com/youtube/answer/72851?hl=en#:~:text=Get%201%2C000%20subscribers%20with%2010,4%2C000%20public%20watch%20hours%20threshold" target="_blank" rel="noreferrer noopener">Learn more</a>',
        setupIcon:
          'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/lightning_icon.png',
        setupTime: 'Setup time 3 minutes',
        prelude: (
          <div className='integrations_page_setup_prelude'>
            The following steps will require you to leave Spring and adjust some
            preferences in your YouTube account.
          </div>
        ),
        shouldShowCTA: true,
        steps: [
          {
            step: 1,
            text: '<button class="steps_button" id="youTubeAddBtn" onclick="window.open(\'https://studio.youtube.com/\', \'_blank\');" type="submit"><img src="https://teespring-ass.s3.amazonaws.com/dashboard/youtube_desktop_06_24.png"/>Go to Youtube Studio</button>'
          },
          {
            step: 2,
            text: 'Click on&nbsp;<span class="integrations_steps_bold">"Earn > Shopping"</span>',
            note: "If you don't see the Shopping tab, you may not be eligible yet <a class='integrations_step_link' href='https://support.google.com/youtube/answer/72851?hl=en#:~:text=Get%201%2C000%20subscribers%20with%2010,4%2C000%20public%20watch%20hours%20threshold' target='_blank' rel='noreferrer noopener'>Learn more</a>"
          },
          {
            step: 3,
            text: 'Click&nbsp;<span class="integrations_steps_bold">"Connect store"</span>'
          },
          {
            step: 4,
            text: 'Select Spring'
          },
          {
            step: 5,
            text: 'Click&nbsp;<span class="integrations_steps_bold">"Continue"</span>'
          },
          {
            step: 6,
            text: 'Log in your Spring account and authorize the integration'
          },
          {
            step: 7,
            text: '<div>Go back to the Earn tab in Youtube Studio and click <span class="integrations_steps_bold">"Finish Setup"</span></div>',
            note: 'If the “Finish Setup” button is not appearing, refresh the browser to see it'
          }
        ]
      },
      howto: {
        prelude: (
          <div className='integrations_page_setup_prelude'>
            Please note it might take up to 72 hours for your products to appear
            in Youtube Studio
          </div>
        ),
        sections: [
          {
            title: 'How to manage store',
            icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Mange.svg',
            steps: [
              {
                text: 'Go to&nbsp;<a class="integrations_step_link" target="_blank" rel="noopener noreferrer" href="https://studio.youtube.com/">YouTube Studio</a>'
              },
              {
                text: 'Click on&nbsp;<span class="integrations_steps_bold">Earn > Shopping</span>'
              },
              {
                text: 'Make sure the Display Products toggle is ON.'
              },
              {
                text: '<div>Click on&nbsp;<span class="integrations_steps_bold">Create Own Selection</span>&nbsp;to customize the product selection displayed in your YouTube Store.</div>'
              },
              {
                text: 'Promote products to fans in your videos!'
              }
            ]
          },
          {
            title: 'How to tag products',
            icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Tag.svg',
            steps: [
              {
                text: 'Go to&nbsp;<a class="integrations_step_link" target="_blank" rel="noopener noreferrer" href="https://studio.youtube.com/">YouTube Studio</a>'
              },
              {
                text: 'Click on&nbsp;<span class="integrations_steps_bold">Content.</span>'
              },
              {
                text: 'Select the video you want to edit'
              },
              {
                text: 'Go to&nbsp;<span class="integrations_steps_bold">Products</span> '
              },
              {
                text: 'Click&nbsp;<span class="integrations_steps_bold">All Stores</span> '
              },
              {
                text: 'Select your Spring store under &nbsp;<span class="integrations_steps_bold">Connected Stores</span> '
              },
              {
                text: 'Tag products in your video'
              }
            ]
          },
          {
            title: 'How to add products to your live stream',
            icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Live.svg',
            steps: [
              {
                text: 'Go to&nbsp;<span class="integrations_steps_bold">Live Control Room</span>'
              },
              {
                text: 'Select an upcoming live stream'
              },
              {
                text: 'Go to&nbsp;<span class="integrations_steps_bold">Shopping</span>'
              },
              {
                text: 'Click&nbsp;<span class="integrations_steps_bold">Customize</span>'
              },
              {
                text: 'Tag products and hit&nbsp;<span class="integrations_steps_bold">Save</span>'
              }
            ]
          }
        ]
      }
    },
    linktreeLegacy: {
      title: 'LinkTree',
      subtitle: 'Showcase and sell products directly on your Linktree profile.',
      bannerImgArray: [
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Linktree_01.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Linktree_02.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Linktree_03.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Linktree_04.png'
      ],
      overviewContent:
        'Showcase and sell products directly on your Linktree to boost sales and broaden your community. Creators who integrate their social accounts are significantly more successful on Spring.',
      features: [
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Showcase.svg',
          title: 'Store widget',
          text: 'Display products inline with your links'
        },
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/views.svg',
          title: 'Store views',
          text: 'Drive traffic to your store'
        }
      ],
      resources: [
        {
          title: 'Linktree guides',
          link: 'https://linktr.ee/blog/shop-ecommerce-linktree-spring-store/',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        },
        {
          title: 'Creator resources',
          link: 'https://www.spri.ng/creator-resources/selling-on-linktree',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        },
        {
          title: 'Support',
          link: 'https://spring4creators.zendesk.com/hc/en-us/requests/new',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        }
      ],
      setup: {
        setupIcon:
          'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/lightning_icon.png',
        setupTime: 'Setup time 3 minutes',
        prelude: (
          <div className='integrations_page_setup_prelude'>
            The following steps will require you to leave Spring and adjust some
            preferences in your Linktree account.
          </div>
        ),
        shouldShowCTA: false,
        steps: [
          {
            step: 1,
            text: `<button class="steps_button" id="linkTreeAddBtn" onclick="window.open('https://linktr.ee/admin?url=${storeUrl}&title=${store?.name}&action=create-link&utm_source=addthisbutton&utm_channel=spring', '_blank');" type="submit"><img src="https://teespring-ass.s3.amazonaws.com/dashboard/Platform=LinktreeSize=Desktop.svg"/>Add this to my Linktree</button>`
          },
          {
            step: 2,
            text: 'Turn on the toggle to show your store on Linktree.'
          }
        ]
      }
    },
    linktree: {
      title: 'LinkTree',
      subtitle: 'Showcase and sell products directly on Linktree.',
      bannerImgArray: [
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Linktree_01-new.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Linktree_02-new.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Linktree_03-new.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Linktree_04-new.png'
      ],
      overviewContent:
        'Showcase products directly on your Linktree store and turn fans into buyers with seamless product discovery',
      features: [
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Linktree-Icon.png',
          title: 'Linktree Store',
          text: 'Display products inline with your links'
        }
      ],
      resources: [
        {
          title: 'Creator resources',
          link: 'https://spring4creators.zendesk.com/hc/en-us',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        },
        {
          title: 'Support',
          link: 'https://spring4creators.zendesk.com/hc/en-us/requests/new',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        }
      ],
      setup: {
        setupIcon:
          'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/lightning_icon.png',
        setupTime: 'Setup time 3 minutes',
        eligibilityTitle: 'Already connected?',
        eligibility:
          'If you linked your Linktree and Spring accounts before February 17th, your connection might still be active and no action is required. To confirm, check your Linktree settings before proceeding with the steps below.',
        prelude: (
          <div className='integrations_page_setup_prelude'>
            The following steps will require you to leave Spring.
          </div>
        ),
        shouldShowCTA: false,
        steps: [
          {
            step: 1,
            text: '<button class="steps_button" id="linkTreeAddBtn" onclick="window.open(\'https://auth.linktr.ee/login\', \'_blank\');" type="submit"><img src="https://teespring-ass.s3.amazonaws.com/dashboard/Platform=LinktreeSize=Desktop.svg"/>Login or Sign up to Linktree</button>'
          },
          {
            step: 2,
            text: 'Go to the&nbsp;<span class="integrations_steps_bold">Links</span>&nbsp;tab and click&nbsp;<span class="integrations_steps_bold">Add</span>'
          },
          {
            step: 3,
            text: 'Search for&nbsp;<span class="integrations_steps_bold">Spring</span>&nbsp;and click&nbsp;<span class="integrations_steps_bold">Add</span>'
          },
          {
            step: 4,
            text: 'Insert a title to the link'
          },
          {
            step: 5,
            text: 'Click&nbsp;<span class="integrations_steps_bold">Add Product</span>&nbsp; and select&nbsp;<span class="integrations_steps_bold">Spring</span>'
          },
          {
            step: 6,
            text: 'Click &nbsp;<span class="integrations_steps_bold">Add a Store Provider</span>&nbsp;and select&nbsp;<span class="integrations_steps_bold">Spring</span>'
          },
          {
            step: 7,
            text: 'Log in your&nbsp;<span class="integrations_steps_bold">Spring account</span>'
          },
          {
            step: 8,
            text: 'Click&nbsp;<span class="integrations_steps_bold">Confirm Provider</span>'
          }
        ]
      },
      howto: {
        label: 'How to use',
        prelude: (
          <div className='integrations_page_setup_prelude'>
            To use this integration, complete setup steps first.
          </div>
        ),
        preludeConnected: (
          <div>
            Your Spring and Linktree accounts are connected. Promote your
            Linktree across all your social channels to start selling.
          </div>
        )
      }
    },
    twitch: {
      title: 'Twitch',
      subtitle: 'Showcase and sell products directly on your Twitch account.',
      bannerImgArray: [
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Twitch-new-asset-1.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Twitch-new-asset-2.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Twitch-new-asset-3.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Twitch-new-asset-4.png'
      ],
      overviewContent:
        'Showcase and sell products directly on your Twitch channel to boost sales and broaden your community.',
      features: [
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Live.svg',
          title: 'Sell on Stream',
          text: 'Sell products under your live stream'
        },
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Alerts.svg',
          title: 'Purchase alerts',
          text: 'Display purchase alerts in the live chat'
        }
      ],
      resources: [
        {
          title: 'Support',
          link: 'https://spring4creators.zendesk.com/hc/en-us/requests/new',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        }
      ],
      setup: {
        setupIcon:
          'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/lightning_icon.png',
        setupTime: 'Setup time 3 minutes',
        prelude: (
          <div className='integrations_page_setup_prelude'>
            The following steps will require you to leave Spring and adjust some
            preferences in your Twitch account.
          </div>
        ),
        shouldShowCTA: false,
        steps: [
          {
            step: 1,
            text: 'Visit the<button class="steps_button" id="twitchAddBtn" onclick="window.open(\'https://dashboard.twitch.tv/extensions/0btmbqa5tm2pyr0f59td8vk1unwxl6\', \'_blank\');" type="submit"><img src="https://teespring-ass.s3.amazonaws.com/dashboard/Platform=TwitchSize=Desktop.svg"/>Spring Extension</button>'
          },
          {
            step: 2,
            text: 'Click&nbsp;<span class="integrations_steps_bold">Install</span>&nbsp;then&nbsp;<span class="integrations_steps_bold">Get Started</span>&nbsp;on the next screen'
          },
          {
            step: 3,
            text: 'Click&nbsp;<span class="integrations_steps_bold">Connect with Twitch</span>'
          },
          {
            step: 4,
            text: 'Authorize then&nbsp;<span class="integrations_steps_bold">Twitch integration</span>'
          },
          {
            step: 5,
            text: 'Go to <the></the><button class="steps_button" id="twitchAddBtn" onclick="window.open(\'https://dashboard.twitch.tv/extensions/0btmbqa5tm2pyr0f59td8vk1unwxl6\', \'_blank\');" type="submit"><img src="https://teespring-ass.s3.amazonaws.com/dashboard/Platform=TwitchSize=Desktop.svg"/>Spring Extension</button>'
          },
          {
            step: 6,
            text: 'Select&nbsp;<span class="integrations_steps_bold">"My Extension"</span>'
          },
          {
            step: 7,
            text: 'Click&nbsp;<span class="integrations_steps_bold">Activate</span>&nbsp;at the bottom of the&nbsp;<span class="integrations_steps_bold">Spring Merch extension</span>'
          },
          {
            step: 8,
            text: 'Select&nbsp;<span class="integrations_steps_bold">"Set as Panel 1"</span>&nbsp;or&nbsp;<span class="integrations_steps_bold">“Add as a new panel”</span>'
          }
        ]
      },
      manage: {
        prelude: (
          <div className='integrations_page_setup_prelude'>
            To use this integration, complete setup steps first. Please note
            that connection activation may take some time, so not all functions
            may work straight away.
          </div>
        ),
        preludeConnected:
          'Your Spring and Twitch accounts are connected. Set the extension as a panel in order to make your Spring products visible to your fans on Twitch.',
        steps: [
          {
            step: 1,
            text: 'Visit the<button class="steps_button" id="twitchAddBtn" onclick="window.open(\'https://dashboard.twitch.tv/extensions/0btmbqa5tm2pyr0f59td8vk1unwxl6\', \'_blank\');" type="submit"><img src="https://teespring-ass.s3.amazonaws.com/dashboard/Platform=TwitchSize=Desktop.svg"/>Spring Extension</button>'
          },
          {
            step: 2,
            text: 'Select&nbsp;<span class="integrations_steps_bold">"My Extension"</span>'
          },
          {
            step: 3,
            text: 'Click&nbsp;<span class="integrations_steps_bold">Activate</span>&nbsp;at the bottom of the&nbsp;<span class="integrations_steps_bold">Spring Merch extension</span>'
          },
          {
            step: 4,
            text: 'Select&nbsp;<span class="integrations_steps_bold">"Set as Panel 1"</span>&nbsp;or&nbsp;<span class="integrations_steps_bold">“Add as a new panel”</span>'
          }
        ]
      }
    },
    discord: {
      title: 'Discord',
      subtitle: 'Boost sales. Display what’s selling in real time',
      bannerImgArray: [
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Discord_01.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Discord_02.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Discord_03.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Discord_04.png'
      ],
      overviewContent:
        'Generate FOMO in your Discord community with real-time sales updates! Set up automated notifications for every purchase, seamlessly showcasing your products in the process.',
      features: [
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Alerts.svg',
          title: 'Purchase alerts',
          text: 'Display purchase alerts in your Discord channel'
        }
      ],
      resources: [
        {
          title: 'Support',
          link: 'https://spring4creators.zendesk.com/hc/en-us/requests/new',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        }
      ],
      setup: {
        setupIcon:
          'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/lightning_icon.png',
        setupTime: 'Setup time 1 minute',
        prelude: (
          <div className='integrations_page_setup_prelude'>
            The following steps will require you to leave Spring and add the
            Spring Merch integration to your Discord account.
          </div>
        ),
        shouldShowCTA: false,
        steps: [
          {
            step: 1,
            text: 'Visit <button class="steps_button" id="discordAddBtn" onclick="window.open(\'https://discord.com/login?redirect_to=%2Foauth2%2Fauthorize%3Fclient_id%3D606933237876523103%26redirect_uri%3Dhttps%253A%252F%252Fteespring.com%252Fdashboard%252Fapi%252Fintegrations%252Fdiscord%252Faccept_connection%26response_type%3Dcode%26scope%3Dwebhook.incoming\', \'_blank\');" type="submit"><img src="https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/discord.svg"/>Spring Merch on Discord</button>'
          },
          {
            step: 2,
            text: 'Select your Discord server'
          },
          {
            step: 3,
            text: 'Choose the channel where you prefer to receive purchase alerts'
          },
          {
            step: 4,
            text: 'Authorize the&nbsp;<span class="integrations_steps_bold">Discord integration</span>'
          }
        ]
      },
      manage: {
        prelude: (
          <div className='integrations_page_setup_prelude'>
            To use this integration, complete setup steps first. Please note
            that connection activation may take some time, so not all functions
            may work straight away.
          </div>
        ),
        preludeConnected: 'Connection with Discord was successful'
      }
    },
    onlyfans: {
      title: 'OnlyFans',
      subtitle: 'Showcase and sell products directly on your OnlyFans channel.',
      bannerImgArray: [
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/OnlyFans_01.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/OnlyFans_02.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/OnlyFans_03.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/OnlyFans_04.png'
      ],
      overviewContent:
        'Showcase and sell products directly on your OnlyFans profile to boost sales and broaden your community. Creators who integrate their social accounts are significantly more successful on Spring.',
      features: [
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Showcase.svg',
          title: 'Store widget',
          text: 'Display products inline with your links'
        },
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/views.svg',
          title: 'Store views',
          text: 'Drive traffic to your store'
        }
      ],
      resources: [
        {
          title: 'Creator Resources',
          link: 'https://blog.onlyfans.com/the-spring-store/',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        },
        {
          title: 'Support',
          link: 'https://spring4creators.zendesk.com/hc/en-us/requests/new',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        }
      ],
      setup: {
        eligibility:
          'To set up a store on OnlyFans, you need to have a verified profile approved for monetization. Apply <a class="integrations_step_link" href="https://start.onlyfans.com/" target="_blank" rel="noreferrer noopener">here.</a>',
        setupIcon:
          'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/lightning_icon.png',
        setupTime: 'Setup time 3 minutes',
        prelude: (
          <div className='integrations_page_setup_prelude'>
            The following steps will require you to leave Spring and adjust some
            preferences in your OnlyFans account.
          </div>
        ),
        shouldShowCTA: false,
        steps: [
          {
            step: 1,
            text: '<button class="steps_button" id="onlyFansAddBtn" onclick="window.open(\'https://onlyfans.com/my/settings/?utm_source=partnerships&utm_medium=in-app&utm_content=campaign\', \'_blank\');" type="submit"><img src="https://teespring-ass.s3.amazonaws.com/dashboard/Platform=OnlyFansSize=Desktop.svg"/>Connect OnlyFans</button>'
          },
          {
            step: 2,
            text: 'Go to your&nbsp;<span class="integrations_steps_bold">Profile Settings</span>'
          },
          {
            step: 3,
            text: 'Select&nbsp;<span class="integrations_steps_bold">Spring Store</span>'
          },
          {
            step: 4,
            text: '<div>Paste your Spring URL and press <span class="integrations_steps_bold">Connect</span></div>',
            note: 'Your embedded store can showcase a maximum of 6 products on OnlyFans.'
          }
        ]
      }
    },
    tiktok: {
      title: 'TikTok',
      subtitle: 'Showcase and sell products directly on TikTok.',
      bannerImgArray: [
        `${s3AssetsBaseUrl}/dashboard/Integrations-gallery-images/tiktok-1.png`,
        `${s3AssetsBaseUrl}/dashboard/Integrations-gallery-images/tiktok-2.png`,
        `${s3AssetsBaseUrl}/dashboard/Integrations-gallery-images/tiktok-3.png`,
        `${s3AssetsBaseUrl}/dashboard/Integrations-gallery-images/tiktok-4.png`
      ],
      overviewContent:
        'Transform your TikTok videos into a profitable storefront, making it effortless for your fans to shop and for you to boost your TikTok earnings.',
      features: [
        {
          icon: `${s3AssetsBaseUrl}/dashboard/Integrations-gallery-images/storefront_logo.png`,
          title: 'Storefront',
          text: 'Showcase products on your profile'
        },
        {
          icon: `${s3AssetsBaseUrl}/dashboard/Integrations-gallery-images/tag_icon.png`,
          title: 'Shoppable Video',
          text: 'Tag products to your video'
        },
        {
          icon: `${s3AssetsBaseUrl}/dashboard/Integrations-gallery-images/live_shopping_logo.png`,
          title: 'Live Shopping',
          text: 'Tag products to your live stream'
        }
      ],
      resources: [
        {
          title: 'TikTok guides',
          link: 'https://seller-us.tiktok.com/university/home?identity=1&role=seller',
          icon: `${s3AssetsBaseUrl}/dashboard/Integrations-gallery-images/link_icon.png`
        },
        {
          title: 'Support',
          link: 'https://spring4creators.zendesk.com/hc/en-us/requests/new',
          icon: `${s3AssetsBaseUrl}/dashboard/Integrations-gallery-images/link_icon.png`
        }
      ],
      setup: {
        eligibilityTitle: 'Early access',
        eligibility:
          'This integration is in early release. Access is currently limited to a select group of users.  To be among the first to experience it, please join our waitlist.',
        shouldShowCTA: false,
        steps: [
          {
            step: '',
            text: '',
            render: <IntegrationPageHubspotButton />
          }
        ]
      }
    },
    storedrop: {
      title: 'Store Drop',
      subtitle:
        'Embed your store anywhere that supports HTML to instantly increase traffic and sales.',
      bannerImgArray: [
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/popupShop_1.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/popupShop_2.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/popupShop_3.png',
        'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/popupShop_4.png'
      ],
      overviewContent:
        'Display and sell your products directly on your website to increase visibility of your products, so you can sell more.',
      features: [
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Showcase.svg',
          title: 'Store widget',
          text: 'Works anywhere that supports HTML'
        },
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/views.svg',
          title: 'Store views',
          text: 'Drive traffic to your store'
        },
        {
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon%3DLightningBlack.png',
          title: 'Real-time updates',
          text: 'Real-time sync. Automatically syncs with every store update.'
        }
      ],
      resources: [
        {
          title: 'Support',
          link: 'https://spring4creators.zendesk.com/hc/en-us/requests/new',
          icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/link_icon.png'
        }
      ],
      setup: {
        shouldShowCTA: false,
        prelude: (
          <WidgetPreview storeslug={store?.slug} title={store?.name} page={1} />
        ),
        steps: [
          {
            step: 1,
            text: 'Customize your&nbsp;<span class="integrations_steps_bold">widget</span>',
            render: <Form title='title' />
          },
          {
            step: 2,
            text: 'Copy&nbsp;<span class="integrations_steps_bold">embed code</span>',
            render: (
              <Embed
                modal={true}
                isPublished={true}
                storeslug={store?.slug}
                title={store?.name}
              />
            )
          },
          {
            step: 3,
            text: '<span class="integrations_steps_bold">Insert HTML code</span>&nbsp;onto any website to embed your Spring store'
          }
        ]
      },
      howto: {
        label: 'How to embed',
        sections: [
          {
            title: 'How to embed to WordPress',
            icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Wordpress-icon.png',
            steps: [
              {
                text: 'Copy your&nbsp;<span class="integrations_steps_bold">Spring Store Drop code.</span>'
              },
              {
                text: 'Log in to your&nbsp;<span class="integrations_steps_bold">WordPress dashboard.</span>'
              },
              {
                text: 'Navigate to the page or post where you want to add the HTML code for your Spring store.'
              },
              {
                text: 'Switch to the Text or HTML editing mode.'
              },
              {
                text: '<span class="integrations_steps_bold">Paste</span>&nbsp;the copied embed code for your Spring store directly into the editor.'
              },
              {
                text: 'Switch back to the Visual mode to preview your changes.'
              },
              {
                text: 'Update or publish your page or post to embed your Spring store into your website.'
              }
            ],
            info: 'Explore the official&nbsp;<span class="integrations_steps_bold">Wordpress resources</span>&nbsp;for further information.',
            render: (
              <button
                className='info_button'
                type='submit'
                onClick={() =>
                  window.open(
                    'https://wordpress.com/support/wordpress-editor/blocks/custom-html-block/',
                    '_blank'
                  )
                }
              >
                <img
                  src='https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon%3DLinkBlack.png'
                  alt='link logo'
                />
                Wordpress resources
              </button>
            )
          },
          {
            title: 'How to embed to Wix',
            icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Wix+-+icon.png',
            steps: [
              {
                text: 'Copy your&nbsp;<span class="integrations_steps_bold">Spring Store Drop code.</span>'
              },
              {
                text: 'Log in to your&nbsp;<span class="integrations_steps_bold">Wix account</span>&nbsp;and open your Wix website editor.'
              },
              {
                text: 'Navigate to the page where you want to embed your Spring store.'
              },
              {
                text: 'Add an&nbsp;<span class="integrations_steps_bold">HTML iFrame</span>&nbsp;element to the page.'
              },
              {
                text: '<span class="integrations_steps_bold">Paste</span>&nbsp;the copied embed code for your Spring store into the HTML iFrame element.'
              },
              {
                text: 'Save your changes to embed your Spring store into your Wix website.'
              }
            ],
            info: 'Explore the official&nbsp;<span class="integrations_steps_bold">Wix resources</span>&nbsp;for further information.',
            render: (
              <button
                className='info_button'
                type='submit'
                onClick={() =>
                  window.open(
                    'https://support.wix.com/en/article/editor-x-adding-an-html-iframe-element',
                    '_blank'
                  )
                }
              >
                <img
                  src='https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon%3DLinkBlack.png'
                  alt='link logo'
                />
                Wix resources
              </button>
            )
          },
          {
            title: 'How to embed to Squarespace',
            icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Squarespace-icon.png',
            steps: [
              {
                text: 'Copy your&nbsp;<span class="integrations_steps_bold">Spring Store Drop code.</span>'
              },
              {
                text: 'Log in to your&nbsp;<span class="integrations_steps_bold">Squarespace account</span>&nbsp;and open your Squarespace website editor.'
              },
              {
                text: 'Navigate to the page where you want to embed your Spring store.'
              },
              {
                text: 'Add a&nbsp;<span class="integrations_steps_bold">code block</span>&nbsp;to the page.'
              },
              {
                text: '<span class="integrations_steps_bold">Paste</span>&nbsp;the copied embed code for your Spring store into the code block.'
              },
              {
                text: 'Save your changes to embed your Spring store into your Squarespace website.'
              }
            ],
            info: 'Explore the official&nbsp;<span class="integrations_steps_bold">Squarespace resources</span>&nbsp;for further information.',
            render: (
              <button
                className='info_button'
                type='submit'
                onClick={() =>
                  window.open(
                    'https://support.squarespace.com/hc/en-us/articles/206543167-Code-blocks',
                    '_blank'
                  )
                }
              >
                <img
                  src='https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon%3DLinkBlack.png'
                  alt='link logo'
                />
                Squarespace resources
              </button>
            )
          },
          {
            title: 'How to embed to Webflow',
            icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Webflow-icon.png',
            steps: [
              {
                text: 'Copy your&nbsp;<span class="integrations_steps_bold">Spring Store Drop code.</span>'
              },
              {
                text: 'Log in to your&nbsp;<span class="integrations_steps_bold">Webflow account</span>&nbsp;and open your Webflow project.'
              },
              {
                text: 'Navigate to the page where you want to embed your Spring store.'
              },
              {
                text: 'Add an&nbsp;<span class="integrations_steps_bold">HTML embed element</span>&nbsp; to the page.'
              },
              {
                text: '<span class="integrations_steps_bold">Paste</span>&nbsp;the copied embed code for your Spring store into the HTML embed element.'
              },
              {
                text: 'Adjust the settings of the HTML embed element, if needed.'
              },
              {
                text: 'Publish your changes to embed your Spring store into your Webflow website.'
              }
            ],
            info: 'Explore the official&nbsp;<span class="integrations_steps_bold">Webflow resources</span>&nbsp;for further information.',
            render: (
              <button
                className='info_button'
                type='submit'
                onClick={() =>
                  window.open(
                    'https://university.webflow.com/lesson/custom-code-embed?topics=elements',
                    '_blank'
                  )
                }
              >
                <img
                  src='https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon%3DLinkBlack.png'
                  alt='link logo'
                />
                Webflow resources
              </button>
            )
          },
          {
            title: 'How to embed to GoDaddy',
            icon: 'https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/GoDaddy-icon.png',
            steps: [
              {
                text: 'Copy your&nbsp;<span class="integrations_steps_bold">Spring Store Drop code.</span>'
              },
              {
                text: 'Log in to your&nbsp;<span class="integrations_steps_bold">GoDaddy account</span>&nbsp;and navigate to your website builder.'
              },
              {
                text: 'Open the page of your website where you want to embed your Spring store.'
              },
              {
                text: 'Add an&nbsp;<span class="integrations_steps_bold">HTML section</span>&nbsp;or widget to the page.'
              },
              {
                text: '<span class="integrations_steps_bold">Paste</span>&nbsp;the copied embed code for your Spring store into the HTML section or widget.'
              },
              {
                text: 'Save your changes to embed your Spring store into your GoDaddy website.'
              }
            ],
            info: 'Explore the official&nbsp;<span class="integrations_steps_bold">GoDaddy resources</span>&nbsp;for further information.',
            render: (
              <button
                className='info_button'
                type='submit'
                onClick={() =>
                  window.open(
                    'https://pt.godaddy.com/help/adicionar-codigo-html-ou-codigo-personalizado-ao-meu-site-na-web-27252',
                    '_blank'
                  )
                }
              >
                <img
                  src='https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon%3DLinkBlack.png'
                  alt='link logo'
                />
                GoDaddy resources
              </button>
            )
          }
        ]
      }
    }
  };
};

export default IntegrationsConfig;
