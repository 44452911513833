import {
  STORE_SLUG_MACRO,
  STORE_PREVIEW_URL_TEMPLATE,
  STORE_URL_TEMPLATE
} from '../../constants';

/**
 * path.join that always uses forward slashes
 *
 * @param paths
 * @returns {string}
 */
function joinSlash(...paths) {
  // use reduce to merge N paths
  return paths.reduce((left, right) => {
    // ignore junk input (including first call)
    if (!left) return right || '';
    if (!right) return left;

    // check bits we want to smush together for existing slashes
    const leftSlash = left.endsWith('/');
    const rightSlash = right.startsWith('/');

    // smush path bits in such a way to only have one slash (except if an input had repeated slashes)
    if (leftSlash && rightSlash) {
      return `${left}${right.substring(1)}`;
    } else if (leftSlash || rightSlash) {
      return `${left}${right}`;
    } else {
      return `${left}/${right}`;
    }
  });
}

/**
 * Get url for a store in the current environment
 *
 * @param {*} storeSlug The slug of the store (my-store-1234)
 * @returns The url to use for a given store
 */
export function getStoreUrl(storeSlug) {
  // expand STORE_SLUG_MACRO ('${storeSlug}') to storeSlug
  return STORE_URL_TEMPLATE.replace(STORE_SLUG_MACRO, storeSlug || '');
}

/**
 * Get url for a store listing in the current environment
 *
 * @param {*} storeSlug The slug of the store (my-store-1234)
 * @param {*} listing The name of the store listing
 * @returns The url to use for a given store's listing
 */
export function getStoreListingUrl(storeSlug, listing) {
  // build listing url
  return joinSlash(getStoreUrl(storeSlug), `/listing/${listing || ''}`);
}

/**
 * Get preview url for a store in the current environment
 *
 * @param {*} storeSlug The slug of the store (my-store-1234)
 * @returns The preview url to use for a given store
 */
export function getStorePreviewUrl(storeSlug) {
  // expand STORE_SLUG_MACRO ('${storeSlug}') to storeSlug
  return STORE_PREVIEW_URL_TEMPLATE.replace(STORE_SLUG_MACRO, storeSlug || '');
}
