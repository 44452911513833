import isMobile from 'is-mobile';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { object, func } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import useOnScreen from 'hooks/useOnscreen';
import IntegrationsPageSetup from '../IntegrationsPageSetup';
import '../IntegrationsPage.scss';
import { IntegrationPageTabs } from '../IntegrationsPageTabs/IntegrationPageTabs';
import { fetchProducts } from '../../../../redux/actions/products';
import { isLinktree, isNilOrEmpty } from '../../../../lib/helpers';
import { useDispatch, useSelector } from 'react-redux';

export const IntegrationsPageContent = ({ integration, logEvent }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [readMore, setReadMore] = useState(false);
  const tabRef = useRef(null);
  const isTabsVisible = useOnScreen(tabRef);
  const { integrationSlug } = useParams();
  const executeFirstScroll = () =>
    tabRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  const isConnected = useSelector(
    (state) => state.integrationConnection.isConnected
  );
  const dispatch = useDispatch();
  const store = useSelector((state) => state.store);
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    if (isLinktree(integration?.title) && isNilOrEmpty(products)) {
      dispatch(fetchProducts(store?.slug));
    }
  }, [products]);

  const splitOverviewContent = () => {
    const s = integration?.overviewContent;
    let middle = Math.floor(s.length / 2);
    const before = s.lastIndexOf(' ', middle);
    const after = s.indexOf(' ', middle + 1);
    if (middle - before < after - middle) {
      middle = before;
    } else {
      middle = after;
    }
    return `${s.substr(0, middle)}...`;
  };
  const notValid = integration?.setup.shouldShowCTA;

  useEffect(() => {
    if (isConnected && !notValid) {
      setCurrentTab(1);
    } else {
      setCurrentTab(0);
    }
  }, [integrationSlug, isConnected, notValid]);
  const addBtnClicked = (social) => {
    logEvent('button.clicked', {
      page: social,
      cta: `connect ${social}`
    });
  };

  useEffect(() => {
    setReadMore(isMobile()); // for users seeing the page from mobile, the content should be expanded and the button hidden
    const linktree = document.getElementById('linkTreeAddBtn');
    const youtube = document.getElementById('youTubeAddBtn');
    const onlyfans = document.getElementById('onlyFansAddBtn');
    const twitch = document.getElementById('twitchAddBtn');
    const discord = document.getElementById('discordAddBtn');

    if (youtube)
      youtube.addEventListener('click', () => addBtnClicked('youtube'));
    if (twitch) twitch.addEventListener('click', () => addBtnClicked('twitch'));
    if (onlyfans)
      onlyfans.addEventListener('click', () => addBtnClicked('onlyfans'));
    if (linktree)
      linktree.addEventListener('click', () => addBtnClicked('linktree'));
    if (discord)
      discord.addEventListener('click', () => addBtnClicked('discord'));
  }, []);

  return (
    <>
      <div className='integrations_content'>
        <div className='integrations_page_instructions' ref={tabRef}>
          <IntegrationPageTabs
            currentTab={currentTab}
            integrationSlug={integrationSlug}
            setCurrentTab={setCurrentTab}
            logEvent={logEvent}
          >
            {integration.setup && (
              <IntegrationPageTabs.Setup
                integrationName={integration?.title}
                integration={integration.setup}
                label='Setup'
                setCurrentTab={setCurrentTab}
                logEvent={logEvent}
              />
            )}
            {integration.manage && (
              <IntegrationPageTabs.Manage
                integration={integration.manage}
                isConnected={isConnected}
                label='Manage'
              />
            )}
            {integration.howto && (
              <IntegrationPageTabs.HowTo
                integrationName={integration?.title}
                integration={integration.howto}
                label={integration.howto?.label || 'How to manage'}
              />
            )}
          </IntegrationPageTabs>
        </div>
        <div className='integrations_page_overview'>
          <div className='integrations_page_overview_content'>
            <div className='integrations_page_overview_title'>Overview</div>
            <span
              dangerouslySetInnerHTML={{
                __html: readMore
                  ? integration?.overviewContent
                  : splitOverviewContent()
              }}
              className='integrations_page_overview_text'
            />
          </div>
          {!isMobile() && (
            <Button
              onClick={() => setReadMore(!readMore)}
              className='integrations_read_more_btn'
              sx={{
                '&:hover': {
                  background: 'transparent',
                  color: '#1954EB'
                }
              }}
              disableRipple={true}
            >
              Read {readMore ? 'less' : 'more'}
            </Button>
          )}
          <div style={{ marginTop: '24px' }}>
            <div
              className='integrations_page_feature_title'
              style={{ marginBottom: '6px' }}
            >
              Features
            </div>
            <div className='integrations_page_features'>
              {integration?.features.map((feature) => {
                return (
                  <div
                    key={feature.title}
                    className='integrations_page_feature'
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={feature.icon}
                        style={{
                          height: '24px',
                          width: '24px',
                          marginRight: '10px'
                        }}
                        alt='feature'
                      />
                      <div className='integrations_feature_title'>
                        {feature.title}
                      </div>
                    </div>

                    <div className='integrations_feature_text'>
                      {feature.text}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className='integrations_page_help_1'
            style={{ marginTop: '24px' }}
          >
            <div
              className='integrations_page_overview_title'
              style={{ marginBottom: '2.5px' }}
            >
              Help and resources
            </div>
            {integration?.resources.map((resource) => {
              return (
                <div
                  key={resource.link}
                  className='integrations_page_resource_link'
                >
                  <a
                    href={resource.link}
                    onClick={() =>
                      logEvent('button.clicked', {
                        button: 'email support',
                        cta: 'contact support'
                      })
                    }
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px'
                      }}
                    >
                      <img
                        src={resource.icon}
                        style={{ marginRight: '10px' }}
                        alt='resource'
                      />
                      <div>{resource.title}</div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className='integrations_page_help_2' style={{ marginTop: '24px' }}>
        <div
          className='integrations_page_overview_title'
          style={{ marginBottom: '2.5px' }}
        >
          Help and resources
        </div>
        {integration?.resources.map((resource) => {
          return (
            <div
              key={resource.link}
              className='integrations_page_resource_link'
            >
              <a
                href={resource.link}
                onClick={() =>
                  logEvent('button.clicked', {
                    button: 'email support',
                    cta: 'contact support'
                  })
                }
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={resource.icon}
                    style={{ marginRight: '10px' }}
                    alt='resource'
                  />
                  <div>{resource.title}</div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
      {!isTabsVisible && (
        <IntegrationsPageSetup scrollTo={executeFirstScroll} />
      )}
    </>
  );
};

IntegrationsPageContent.propTypes = {
  integration: object.isRequired,
  logEvent: func.isRequired
};

export default IntegrationsPageContent;
