import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import get from 'lodash/get';
import find from 'lodash/find';
import { getStorePreviewUrl, getStoreUrl } from 'lib/store';

const usePreviewUrl = (
  store,
  storePreviewData,
  preview = true,
  extendedPath = ''
) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [storeUrl, setStoreUrl] = useState('');
  const storeId = get(store, 'id');
  const userStore = useSelector((state) =>
    find(get(state, 'user.stores'), ['id', storeId])
  );
  let primaryUrl = get(userStore, 'primaryUrl');

  if (primaryUrl) {
    primaryUrl = primaryUrl.match('^http(s?)://')
      ? primaryUrl
      : `https://${primaryUrl}`;
  }

  useEffect(() => {
    const secretKey = process.env.REACT_APP_PREVIEW_SECRET_KEY || '';
    const initialPayload = { preview };
    const storeSlug = get(store, 'slug');
    const storeLink = getStorePreviewUrl(storeSlug);

    let forPreview =
      (storeLink ?? '').slice(-1) === '/'
        ? `${storeLink}${extendedPath}`
        : `${storeLink}/${extendedPath}`;

    const payload = storePreviewData
      ? {
          ...storePreviewData,
          ...initialPayload
        }
      : initialPayload;

    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(payload),
      secretKey
    ).toString();

    forPreview += `#previewData=${ciphertext}`;

    setPreviewUrl(forPreview);
    setStoreUrl(primaryUrl ?? getStoreUrl(storeSlug));
  }, [store, storePreviewData, preview, extendedPath, primaryUrl]);

  return { previewUrl, storeUrl };
};

export default usePreviewUrl;
