import React from 'react';

export const IntegrationsCreatorPlaybookCard = () => {
  return (
    <section className='integrations_page_card'>
      <div className='integrations_page_card_title'>
        Check out our guide for creating, launching and selling products
      </div>
      <button
        className='steps_button'
        id='linkTreeAddBtn'
        onClick={() =>
          window.open('https://hub.spri.ng/guides/playbook-24', '_blank')
        }
        type='submit'
      >
        <img src='https://teespring-ass.s3.amazonaws.com/dashboard/Integrations-gallery-images/Icon=Sparks.png' />
        Creator Playbook
      </button>
    </section>
  );
};
