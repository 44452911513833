// Grid lines styles
const lineColor = '#D9D9D9';
const lineThickness = 1;

export const sharedLineStyle = {
  stroke: lineColor,
  strokeWidth: lineThickness
};

export const PRODUCT_COLOR = '#5281CC';

export const PRODUCT_TYPE_COLORS = ['#5281CC', '#FF9168', '#7F42A3'];

// Profit over time chart
export const TOTAL_PROFIT_COLOR = '#017B40';
export const UNITS_SOLD_COLOR = '#7F42A3';
export const VIEWS_COLOR = '#E56F01';

export const SALES_BY_TIME_COLOR = '#7F42A3';

// Actionable Insight messages
export const INSIGHT_MESSAGES = {
  purchased_product: {
    title: 'You’ve got popular products to show off in your content',
    text: 'Congrats on having a few fan favorites! Capitalize on your top seller by featuring it in your headshot or profile photo, Throwback Thursday content, and promo materials so you can build FOMO for those fans who haven’t purchased it yet.'
  },
  purchased_category: {
    title: 'Lead with your top-selling product category in promotions',
    text: 'This chart shows which product type you sell the most. When promoting new listings, lean into what your audience already loves. Pair new products with attention-grabbing photos and promos featuring a hit item from this category—it’s a surefire way to make an impact and capture attention!'
  },
  sales_by_time: {
    title: 'Take advantage of your high traffic times',
    text: 'These charts reveal when your fans are most active and making the most purchases—broken down by day of the week and time of day. Use this insight to maximize impact by sharing your new listings and promotions on social media during peak activity times!'
  }
};
