import { API_HOST } from '../../../constants';
import axios from 'axios';
import {
  assertStoreExists,
  StoreDoesNotExistError
} from '../../../lib/Integrations';
import { TrackJS } from 'trackjs';

export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const PRODUCTS_ERROR = 'PRODUCTS_ERROR';

export const fetchProducts = (storeSlug, per = 1) => {
  return async (dispatch) => {
    try {
      assertStoreExists(storeSlug);
      const { data } = await axios.get(`${API_HOST}/api/v1/stores/products`, {
        params: { slug: storeSlug, per: per }
      });
      dispatch({
        type: RECEIVE_PRODUCTS,
        storeSlug: storeSlug,
        productsData: data
      });
    } catch (err) {
      if (err instanceof StoreDoesNotExistError) {
        if (TrackJS.isInstalled()) TrackJS.track(err);
      }
      dispatch({ type: PRODUCTS_ERROR, err: err.message });
    }
  };
};
