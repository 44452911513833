import React from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Loader } from '@teespring/ts-components';
import { useListings } from 'hooks';
import ErrorPage from 'components/ErrorPage';
import ListingAdminBar from './ListingAdminBar';
import './Listing.scss';
import { getStoreListingUrl } from 'lib/store';

const Listing = () => {
  const store = useSelector((state) => state.store);
  const { listingId } = useParams();
  const { listing, previewUrl, listingError, activeStore } = useListings({
    listingId
  });
  const storefrontIsLoaded = !isEmpty(store) && !isEmpty(listing);
  const storeSlug = get(activeStore, 'slug');
  const listingSlug = get(listing, 'url');
  const listingUrl =
    storeSlug && listingSlug ? getStoreListingUrl(storeSlug, listingSlug) : '';

  return (
    <div className='listing'>
      <ListingAdminBar listingUrl={listingUrl} listingError={listingError} />

      {!listingError ? (
        <div className='listing__frame'>
          {storefrontIsLoaded ? (
            <iframe title={get(listing, 'title')} src={previewUrl} />
          ) : (
            <Loader />
          )}
        </div>
      ) : (
        <ErrorPage type='listing' />
      )}
    </div>
  );
};

export default Listing;
