import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAmplitude } from 'hooks';
import useIntegrations from 'hooks/useIntegrations';
import { useDispatch, useSelector } from 'react-redux';
import IntegrationsConfig from './config/IntegrationsConfig';
import IntegrationsPageSlider from './IntegrationsPageSlider';
import IntegrationsPageTitle from './IntegrationsPageTitle';
import IntegrationsPageSubtitle from './IntegrationsSubtitle';
import IntegrationsPageContent from './IntegrationsPageContent';
import './IntegrationsPage.scss';
import {
  userIntegrated,
  userNotIntegrated
} from '../../../redux/actions/integratedConnection';

export const IntegrationsPageTemplate = () => {
  const { logEvent } = useAmplitude();
  const { getIntegrationData } = useIntegrations();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const { email } = useSelector((state) => state.header.user);
  const { integrations: socialIntegrations } = useIntegrations();
  const newLinktreeExperienceEnabled =
    process.env.REACT_APP_NEW_LINKTREE_EXPERIENCE_ENABLED === 'true' ||
    email.endsWith('@linktr.ee') ||
    email.endsWith('@spri.ng') ||
    email.endsWith('@amaze.co');

  let { integrationSlug } = useParams();
  if (integrationSlug === 'linktree' && !newLinktreeExperienceEnabled) {
    integrationSlug = 'linktreeLegacy';
  }
  const integrations = IntegrationsConfig(user);
  const integration = socialIntegrations.find(
    (integr) => integr.name === integrations[integrationSlug]?.title
  );

  const integrationData = integrations[integrationSlug];

  const logIntegrationPageEvent = (eventName, params) =>
    logEvent(eventName, {
      journey: 'dashboard',
      feature: 'integrations',
      page: integrationSlug,
      url: window.location.href,
      ...params
    });

  const connected = async () => {
    const status = await getIntegrationData();
    if (status?.[integrationSlug]) {
      dispatch(userIntegrated());
    } else {
      dispatch(userNotIntegrated());
    }

    logEvent('page.viewed', {
      journey: 'dashboard',
      feature: 'integrations',
      page: integrationSlug,
      url: window.location.href,
      state: status?.[integrationSlug]
    });
  };
  useEffect(() => {
    connected();
  }, [integrationSlug]);
  return (
    <div className='integrations_page_container'>
      <IntegrationsPageTitle
        title={integrationData?.title}
        iconUrl={integration?.logo}
      />
      <IntegrationsPageSubtitle subtitle={integrationData?.subtitle} />
      <IntegrationsPageSlider imageUrlArray={integrationData?.bannerImgArray} />
      <IntegrationsPageContent
        integration={integrationData}
        logEvent={logIntegrationPageEvent}
      />
    </div>
  );
};

export default IntegrationsPageTemplate;
