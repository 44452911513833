import { isNil, isEmpty } from 'lodash';

export class StoreDoesNotExistError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'StoreDoesNotExistError';

    Object.setPrototypeOf(this, StoreDoesNotExistError.prototype);
  }
}

export const assertStoreExists = (storeSlug: string | undefined) => {
  if (isNil(storeSlug) || isEmpty(storeSlug)) {
    throw new StoreDoesNotExistError("Didn't find store for user");
  }
};
