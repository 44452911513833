import React from 'react';
import { Tooltip } from '@teespring/ts-components';
import bolt from '../../assets/bolt.svg';

// Define the props type
interface ActionableInsightProps {
  message: {
    title: string;
    text: string;
  };
  alignment?: string; // Optional, defaults to 'align-right'
}

const ActionableInsight: React.FC<ActionableInsightProps> = ({
  message,
  alignment = 'align-left'
}) => {
  return (
    <Tooltip
      className='actionableInsight'
      toggle={<img src={bolt} alt='bolt icon' />}
      alignment={alignment}
      content={
        <div className='analyticsInsight'>
          <div className='header'>
            <img src={bolt} alt='bolt icon' /> Actionable Insight
          </div>
          <h2>{message.title}</h2>
          <p>{message.text}</p>
        </div>
      }
    />
  );
};

export default ActionableInsight;
