import { combineReducers } from 'redux';
import analyticsOverview from './analyticsOverview';
import analytics from './analytics';
import breakpoint from './breakpoint';
import collectionProducts from './collectionProducts';
import collections from './collections';
import customDomain from './customDomain';
import embedStoreParams from './embedStoreParams';
import header from './header';
import httpError from './errorReducer';
import integrationConnection from './integrationConnection';
import listing from './listing';
import modal from './modal';
import overview from './overview';
import samples from './samples';
import store from './store';
import storePreviewData from './storePreviewData';
import subscriptions from './subscriptions';
import toast from './toast';
import user from './user';
import products from './products';

export default combineReducers({
  analyticsOverview,
  analytics,
  breakpoint,
  collectionProducts,
  collections,
  customDomain,
  embedStoreParams,
  header,
  httpError,
  integrationConnection,
  listing,
  products,
  modal,
  overview,
  samples,
  store,
  storePreviewData,
  subscriptions,
  toast,
  user
});
